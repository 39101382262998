<template>
  <v-list flat subheader three-line>
    <v-subheader>{{ $t("notification_settings") }}</v-subheader>

    <v-list-item-group multiple active-class="">
      <v-list-item v-for="item in _settings.preferences" :key="item.name">
        <template v-slot:default>
          <v-list-item-action>
            <v-switch
              inset
              :class="
                item.id == 'notification.important' ? 'switch-like-active' : ''
              "
              :disabled="item.disabled"
              :color="
                item.disabled
                  ? item.id == 'notification.important'
                    ? 'primary'
                    : 'gray'
                  : 'primary'
              "
              false-value="0"
              true-value="1"
              v-model="item.value"
            ></v-switch>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "NotificationSettings",
  props: {
    settings: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    _settings: {
      get: function () {
        return this.settings;
      },
      set: function (newValue) {
        return this.$emit("update:settings", newValue);
      },
      deep: true,
    },
  },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {},
};
</script>

<style scoped>
::v-deep .v-list-item__subtitle {
  -webkit-line-clamp: 3 !important;
}

.switch-like-active,
.switch-like-active.v-input--switch.v-input--is-dirty.v-input--is-disabled {
  color: #495bc4 !important;
  caret-color: #495bc4 !important;
  opacity: 1 !important;
}
</style>
