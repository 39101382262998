<template>
  <v-list flat subheader three-line>
    <v-subheader>
      {{ $t("other") }}
    </v-subheader>

    <v-list-item>
      <template v-slot:default>
        <v-list-item-content>
          <v-list-item-title>{{ $t("other_settings") }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("other_settings_message") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "OtherSettings",
  props: {
    settings: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    _settings: {
      get: function () {
        return this.settings;
      },
      set: function (newValue) {
        return this.$emit("update:settings", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
